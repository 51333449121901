<template>
  <div class="user-tab-security">
    <div class="max-h-content-container app-chat position-relative overflow-hidden text-sm">
      <div class="chat-content-area h-full d-flex flex-column">
        <div class="h-full">
          <div class="d-flex align-center justify-space-between px-5 py-4" style="height: 72px">
            <div class="d-flex align-center">
              <div class="d-flex flex-column">
                <p v-t="'internalNotes'" class="mb-0 text--primary font-weight-medium" />
              </div>
            </div>
          </div>

          <v-divider></v-divider>
          <perfect-scrollbar ref="refInternalNotesLogPS" :options="perfectScrollbarOptions" class="ps-chat-log h-full">
            <internal-note-log
              :internal-notes-data="internalNotes"
              :recruiter="recruiter"
              @delete-internal-note="deleteInternalNote"
            ></internal-note-log>
          </perfect-scrollbar>

          <v-form autocomplete="off" @submit.prevent="sendInternalNote">
            <v-text-field
              v-model="internalNotesMessage"
              :placeholder="$t('typeYourNote')"
              solo
              class="pb-5 px-5 flex-grow-0 msg-input"
              hide-details
              maxlength="5000"
              height="50"
              autofocus
            >
              <template #append>
                <v-btn
                  color="primary"
                  elevation="0"
                  type="submit"
                  :icon="$vuetify.breakpoint.xsOnly"
                  :loading="loadingSendInternalNote"
                >
                  <template v-if="$vuetify.breakpoint.smAndUp">{{ $t('send') }}</template>
                  <template v-else>
                    <v-icon>{{ icons.mdiSendOutline }}</v-icon>
                  </template>
                </v-btn>
              </template>
            </v-text-field>
          </v-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiSendOutline } from '@mdi/js'
import { ref, nextTick, onMounted } from '@vue/composition-api'
import { useActions, useState } from 'vuex-composition-helpers'
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'

import { confirmPasswordValidator, passwordValidator, required } from '@core/utils/validation'
import InternalNoteLog from './InternalNoteLog.vue'

// TODO: Create common view
export default {
  components: { PerfectScrollbar, InternalNoteLog },
  setup() {
    const { getHouseInternalNote, createHouseInternalNote, deleteHouseInternalNote } = useActions('staff', [
      'getHouseInternalNote',
      'createHouseInternalNote',
      'deleteHouseInternalNote',
    ])
    const { houseData } = useState('staff', ['houseData'])
    const { recruiter } = useState('recruiter', ['recruiter'])

    const loadingSendInternalNote = ref(false)

    const internalNotes = ref([])
    const internalNotesMessage = ref('')

    const refInternalNotesLogPS = ref(null)
    const scrollToBottomInInternalNotes = () => {
      const scrollEl = refInternalNotesLogPS.value.$el || refInternalNotesLogPS.value
      scrollEl.scrollTop = scrollEl.scrollHeight
    }

    const deleteInternalNote = async (internalNoteId) => {
      const { success } = await deleteHouseInternalNote(internalNoteId)
      if (success) {
        const { data } = await getHouseInternalNote(houseData.value.id)
        internalNotes.value = data
      }
    }

    const sendInternalNote = async () => {
      if (!internalNotesMessage.value) return

      loadingSendInternalNote.value = true

      const { success } = await createHouseInternalNote({
        house: houseData.value.id,
        note: internalNotesMessage.value,
        createdBy: recruiter.value.id,
      })

      if (success) {
        const { data } = await getHouseInternalNote(houseData.value.id)
        internalNotes.value = data
      }

      loadingSendInternalNote.value = false
      internalNotesMessage.value = ''
      nextTick(() => {
        nextTick(() => {
          scrollToBottomInInternalNotes()
        })
      })
    }

    // Perfect scrollbar options
    const perfectScrollbarOptions = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    onMounted(async () => {
      const { success, data } = await getHouseInternalNote(houseData.value.id)
      if (success) internalNotes.value = data
      nextTick(() => {
        scrollToBottomInInternalNotes()
      })
    })

    return {
      recruiter,
      sendInternalNote,
      deleteInternalNote,

      internalNotes,
      internalNotesMessage,
      loadingSendInternalNote,
      perfectScrollbarOptions,
      refInternalNotesLogPS,
      icons: {
        mdiSendOutline,
      },
      validators: {
        required,
        confirmPasswordValidator,
        passwordValidator,
      },
    }
  },
}
</script>
