<template>
  <div id="user-view">
    <v-row>
      <v-col cols="12" md="5" lg="4">
        <bread-crumbs
          :context="{
            houseName: `${houseData.name}`,
          }"
        />
        <house-bio-panel :loading="loading" />
      </v-col>

      <v-col cols="12" md="7" lg="8">
        <v-tabs v-model="userTab" show-arrows class="user-tabs">
          <v-tab v-for="tab in tabs" :key="tab.icon">
            <v-icon size="20" class="me-3">
              {{ tab.icon }}
            </v-icon>
            <span>{{ $t(`${tab.title}`) }}</span>
          </v-tab>
        </v-tabs>

        <v-tabs-items id="user-tabs-content" :key="houseData.id" v-model="userTab" class="mt-5 pa-1" touchless>
          <v-tab-item>
            <house-tab-rooms v-if="houseData.id" :key="componentTabKey" @refetch-data="fetchHouseData" />
          </v-tab-item>

          <v-tab-item>
            <house-tab-files :key="componentTabKey" />
          </v-tab-item>

          <v-tab-item>
            <house-tab-reminders v-if="userTab === 2" @refetch-data="fetchHouseData" />
          </v-tab-item>

          <v-tab-item>
            <house-tab-custom-fields :key="componentTabKey" />
          </v-tab-item>

          <v-tab-item>
            <house-tab-internal-notes v-if="userTab === 4" :key="componentTabKey" />
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted, watch } from '@vue/composition-api'
import { mdiAccountOutline, mdiAccountCancelOutline, mdiLockOutline, mdiBookEditOutline, mdiBellOutline } from '@mdi/js'
import { useActions, useMutations, useState } from 'vuex-composition-helpers'
import { useRouter } from '@core/utils'
import router from '@/router'
// eslint-disable-next-line object-curly-newline
import HouseBioPanel from './house-bio-panel/HouseBioPanel.vue'
import HouseTabInternalNotes from './house-tab-internal-notes/HouseTabInternalNotes.vue'
import HouseTabRooms from './house-tab-rooms/HouseTabRooms.vue'
import HouseTabFiles from './house-tab-files/HouseTabFiles.vue'
import HouseTabCustomFields from './house-tab-custom-fields/HouseTabCustomFields.vue'
import BreadCrumbs from '@/components/files-table/BreadCrumbs.vue'
import HouseTabReminders from '@/views/apps/staff/houses/house-view/house-tab-reminders/HouseTabReminders.vue'

export default {
  components: {
    BreadCrumbs,
    HouseBioPanel,
    HouseTabInternalNotes,
    HouseTabRooms,
    HouseTabFiles,
    HouseTabCustomFields,
    HouseTabReminders,
  },
  setup() {
    const tabs = [
      { icon: mdiAccountCancelOutline, title: 'rooms' },
      { icon: mdiLockOutline, title: 'files' },
      { icon: mdiBellOutline, title: 'reminders' },
      { icon: mdiBookEditOutline, title: 'customFields' },
      { icon: mdiAccountOutline, title: 'internalNotes' },
    ]

    const userTab = ref(null)
    const loading = ref(false)

    const { getHouseDetails } = useActions('staff', ['getHouseDetails'])
    const { houseData } = useState('staff', ['houseData'])
    const { SET_HOUSE_DATA } = useMutations('staff', ['SET_HOUSE_DATA'])

    const componentTabKey = ref(0)
    const forceRerenderTab = () => {
      componentTabKey.value += 1
    }

    const fetchHouseData = async () => {
      loading.value = true
      await getHouseDetails(router.currentRoute.params.id)
      loading.value = false
      forceRerenderTab()
    }

    const { route } = useRouter()

    watch(
      () => route.value.params.id,
      async () => {
        await fetchHouseData()
      },
    )

    onMounted(async () => {
      await fetchHouseData()
    })

    onUnmounted(() => {
      SET_HOUSE_DATA({
        rooms: [],
      })
    })

    return {
      fetchHouseData,

      componentTabKey,
      tabs,
      userTab,
      houseData,
      loading,
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
