<template>
  <div class="user-tab-security">
    <file-table
      :loading="loading"
      :files="localFiles"
      :file-types="fileTypes"
      handle-reminders
      handle-document-generation
      handle-types-and-valid-until
      :document-generator-data="{
        module: MODULE_HOUSES,
        obj: houseData,
        target: houseData.name,
      }"
      :upload-file-callback="uploadHouseFiles"
      :disable-delete="!$can(ACTIONS.DELETE, PRIVATE.STAFF.HOUSES_FILES)"
      :delete-file-callback="deleteHouseFile"
      :update-file-callback="updateHouseFile"
      :file-target-object="{ houseId: houseData.id }"
      :reminder-target-object="{ house: houseData.id }"
      @refetch-data="refetchData"
    />
  </div>
</template>

<script>
import { ref, onMounted } from '@vue/composition-api'
import { useActions, useState } from 'vuex-composition-helpers'

import _ from 'lodash'
import houseUtils from '@/utils/houseUtils'
import router from '@/router'
import { ACTIONS, PRIVATE } from '@/plugins/acl/const'
import documentTemplateUtils from '@/utils/templates/document/documentTemplateUtils'
import FileTable from '@/components/files-table/FileTable.vue'

export default {
  components: {
    FileTable,
  },
  setup() {
    const { deleteHouseFile, updateHouseFile, uploadHouseFiles, getHouseDetails } = useActions('staff', [
      'deleteHouseFile',
      'updateHouseFile',
      'uploadHouseFiles',
      'getHouseDetails',
    ])
    const { houseData } = useState('staff', ['houseData'])

    const { fetchFileTypes, fileTypes } = houseUtils()

    const { MODULE_HOUSES } = documentTemplateUtils()

    const localFiles = ref([])
    const loading = ref(false)

    localFiles.value = _.cloneDeep(houseData.value.files)

    onMounted(async () => {
      await fetchFileTypes()
    })

    const refetchData = async () => {
      loading.value = true
      await getHouseDetails(router.currentRoute.params.id)
      localFiles.value = _.cloneDeep(houseData.value.files)
      await fetchFileTypes()
      loading.value = false
    }

    return {
      refetchData,
      deleteHouseFile,
      updateHouseFile,
      uploadHouseFiles,

      houseData,
      fileTypes,
      localFiles,
      loading,

      MODULE_HOUSES,
      PRIVATE,
      ACTIONS,
    }
  },
}
</script>
<style scoped lang="scss"></style>
