<template>
  <!-- edit profile dialog -->
  <v-dialog v-model="isBioDialogOpen" max-width="650px" @click:outside="$emit('update:is-bio-dialog-open', false)">
    <v-card class="user-edit-info pa-sm-10 pa-3">
      <v-card-title v-t="'Edit'" class="justify-center text-h5" />

      <v-card-text class="mt-5">
        <v-form ref="bioEditForm" v-model="valid" class="multi-col-validation" @submit.prevent="handleFormSubmit">
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="houseDataLocal.name"
                outlined
                dense
                maxlength="150"
                :label="$t('name')"
                :placeholder="$t('name')"
                :rules="[validators.required]"
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-textarea
                v-model="houseDataLocal.description"
                outlined
                auto-grow
                :label="$t('description')"
                :placeholder="$t('description')"
                rows="4"
                maxlength="1000"
              />
            </v-col>

            <v-col cols="12">
              <v-autocomplete
                v-model="houseDataLocal.status"
                :label="$t('status', { postfix: '' })"
                :placeholder="$t('status', { postfix: '' })"
                :items="statusChoices"
                outlined
                dense
                clearable
                hide-details
              ></v-autocomplete>
            </v-col>

            <v-col cols="12">
              <v-combobox
                v-model="houseDataLocal.tags"
                :items="tagsChoices"
                :label="$t('tag')"
                :placeholder="$t('tag')"
                item-text="tag"
                item-value="id"
                multiple
                outlined
                dense
                hide-details
              >
                <template v-slot:selection="{ attrs, item, parent, selected }">
                  <v-chip
                    v-bind="attrs"
                    :input-value="selected"
                    small
                    label
                    color="info"
                    outlined
                    class="font-weight-semibold disable-min-height--chip mt-1"
                  >
                    <span class="pr-3">{{ item === Object(item) ? item.tag : item }}</span>
                    <v-icon x-small @click="parent.selectItem(item)"> $delete </v-icon>
                  </v-chip>
                </template>
              </v-combobox></v-col
            >

            <v-col cols="12">
              <v-text-field
                v-model="houseDataLocal.street"
                outlined
                dense
                :label="$t('street')"
                :placeholder="$t('street')"
                maxlength="100"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="houseDataLocal.houseNumber"
                outlined
                dense
                :label="$t('houseNumber')"
                :placeholder="$t('houseNumber')"
                maxlength="20"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field
                v-model="houseDataLocal.flatNumber"
                outlined
                dense
                :label="$t('flatNumber')"
                :placeholder="$t('flatNumber')"
                maxlength="20"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="8"
              ><v-text-field
                v-model="houseDataLocal.city"
                outlined
                dense
                :label="$t('city')"
                :placeholder="$t('city')"
                maxlength="50"
              ></v-text-field
            ></v-col>

            <v-col cols="12" sm="4"
              ><v-text-field
                v-model="houseDataLocal.zipCode"
                outlined
                dense
                :label="$t('zipCode')"
                :placeholder="$t('zipCode')"
                maxlength="10"
              ></v-text-field
            ></v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="6"
              ><v-text-field
                v-model="houseDataLocal.state"
                outlined
                dense
                :label="$t('state')"
                :placeholder="$t('state')"
                maxlength="50"
              ></v-text-field
            ></v-col>

            <v-col cols="12" sm="6"
              ><v-text-field
                v-model="houseDataLocal.country"
                outlined
                dense
                :label="$t('country')"
                :placeholder="$t('country')"
                maxlength="50"
              ></v-text-field
            ></v-col>

            <v-col cols="12" class="d-flex justify-center mt-3">
              <v-btn
                v-t="'discard'"
                outlined
                color="secondary"
                class="me-3"
                @click.prevent="$emit('update:is-bio-dialog-open', false)"
              />

              <v-btn color="primary" type="submit" :loading="loading" :disabled="!valid || !areDataChanged">{{
                $t('save')
              }}</v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref, watch, computed } from '@vue/composition-api'
import { useActions } from 'vuex-composition-helpers'
import { required } from '@core/utils/validation'
import _ from 'lodash'
import houseUtils from '@/utils/houseUtils'

export default {
  props: {
    isBioDialogOpen: {
      type: Boolean,
      required: true,
    },
    houseData: {
      type: Object,
      required: true,
    },
    statusChoices: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    const valid = ref(true)
    const loading = ref(false)

    const bioEditForm = ref(null)

    const houseDataLocal = ref({})
    houseDataLocal.value = _.cloneDeep(props.houseData)

    const { updateHouseDetails } = useActions('staff', ['updateHouseDetails'])

    const {
      fetchTagChoices,

      tagsChoices,
    } = houseUtils()

    const handleFormSubmit = async () => {
      const isFormValid = bioEditForm.value.validate()
      if (!isFormValid) return

      loading.value = true
      const { success } = await updateHouseDetails({
        ...houseDataLocal.value,
        tags: houseDataLocal.value.tags.map((tag) => {
          if (_.isObject(tag)) return tag

          return { tag }
        }),
      })
      loading.value = false

      if (success) {
        emit('update:is-bio-dialog-open', false)
      }
    }

    watch(
      () => props.isBioDialogOpen,
      async (newValue) => {
        if (newValue) {
          houseDataLocal.value = _.cloneDeep(props.houseData)
          await fetchTagChoices()
        }
      },
    )

    const areDataChanged = computed(() => {
      return (
        houseDataLocal.value.name !== props.houseData.name ||
        houseDataLocal.value.description !== props.houseData.description ||
        houseDataLocal.value.status !== props.houseData.status ||
        houseDataLocal.value.street !== props.houseData.street ||
        houseDataLocal.value.houseNumber !== props.houseData.houseNumber ||
        houseDataLocal.value.flatNumber !== props.houseData.flatNumber ||
        houseDataLocal.value.city !== props.houseData.city ||
        houseDataLocal.value.zipCode !== props.houseData.zipCode ||
        houseDataLocal.value.state !== props.houseData.state ||
        houseDataLocal.value.country !== props.houseData.country ||
        !_.isEmpty(_.differenceWith(houseDataLocal.value.tags, props.houseData.tags, _.isEqual)) ||
        !_.isEmpty(_.differenceWith(props.houseData.tags, houseDataLocal.value.tags, _.isEqual))
      )
    })

    return {
      handleFormSubmit,

      valid,
      loading,
      bioEditForm,
      houseDataLocal,
      areDataChanged,
      tagsChoices,

      validators: {
        required,
      },
    }
  },
}
</script>
