<template>
  <div class="user-tab-security">
    <!-- change password -->
    <v-card class="mb-7">
      <v-card-title class="d-flex justify-space-between align-center"
        >{{ $t('rooms') }}
        <v-btn small color="primary" class="me-3" @click.stop="isAddRoomDrawerVisible = true">
          <v-icon left>{{ icons.mdiPlus }}</v-icon>
          <span v-t="'addNewRoom'" />
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col v-for="(room, index) in houseData.rooms" :key="`Room--${index}`" cols="12">
            <v-card outlined class="card--border-primary">
              <v-card-title>
                <v-row align-content="center" justify="space-between">
                  <v-col cols="9" sm="10">
                    {{ room.name }}
                  </v-col>
                  <v-col cols="auto" class="text-sm"> {{ room.currentOccupantsCount }} / {{ room.demand }} </v-col>
                </v-row>
              </v-card-title>
              <v-card-text>
                <v-row no-gutters>
                  <v-col
                    v-for="(occupant, occupantIndex) in room.currentOccupants"
                    :key="`Occupant--${occupant.id}-${occupantIndex}`"
                    cols="auto"
                  >
                    <v-chip
                      v-if="!occupant.occupied"
                      label
                      small
                      color="info"
                      :to="{ name: 'apps-employee-view', params: { id: occupant.employee } }"
                      class="v-chip-light-bg info--text font-weight-semibold text-capitalize ma-1 mb-2"
                      ><span>{{ occupant.firstName }} {{ occupant.lastName }}</span>
                      <v-icon color="info" right>{{ icons.mdiChevronRight }}</v-icon></v-chip
                    >
                    <v-chip
                      v-else
                      label
                      small
                      color="secondary"
                      class="v-chip-light-bg secondary--text font-weight-semibold text-capitalize ma-1 mb-2"
                      ><span>{{ occupant.note }}</span>
                    </v-chip>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-row align-content="center">
                  <v-col v-if="$can(ACTIONS.READ, PRIVATE.SHINE_FEATURES)" cols="3">
                    <span class="font-weight-bold me-2">{{ $t('futureCount') }}:</span>
                    <span class="text--secondary">{{ room.futureOccupantsCount }}</span>
                  </v-col>
                  <v-col v-if="$can(ACTIONS.READ, PRIVATE.SHINE_FEATURES)" cols="3">
                    <span class="font-weight-bold me-2">{{ $t('pastCount') }}:</span>
                    <span class="text--secondary">{{ room.pastOccupantsCount }}</span>
                  </v-col>
                  <v-spacer />
                  <v-col cols="auto">
                    <v-btn
                      right
                      small
                      depressed
                      color="primary"
                      :to="{ name: 'apps-house-room-view', params: { id: room.id } }"
                    >
                      <span v-t="'details'" />
                      <v-icon right>{{ icons.mdiChevronRight }}</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-navigation-drawer
      v-model="isAddRoomDrawerVisible"
      temporary
      touchless
      :right="!$vuetify.rtl"
      :width="$vuetify.breakpoint.mdAndUp ? '40%' : '100%'"
      app
      @input="(val) => !val && discardAdd()"
    >
      <div class="drawer-header d-flex align-center">
        <span v-t="'addRoom'" class="font-weight-semibold text-base text--primary" />
        <v-spacer></v-spacer>
        <v-btn icon small @click="isAddRoomDrawerVisible = false">
          <v-icon size="22">
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </div>
      <v-form ref="addRoomForm" @submit.prevent="confirmAdd">
        <v-card-text>
          <v-subheader v-t="'basicData'" />
          <v-text-field
            v-model="addRoomData.name"
            outlined
            dense
            maxlength="150"
            hide-details="auto"
            :label="$t('name')"
            :placeholder="$t('name')"
            :rules="[validators.required]"
            class="mb-3"
          ></v-text-field>
          <v-text-field
            v-model="addRoomData.demand"
            :label="$t('accommodationDemand')"
            :placeholder="$t('accommodationDemand')"
            outlined
            dense
            type="number"
            min="0"
            hide-details="auto"
            :rules="[validators.minPositiveValue]"
            class="mb-3"
          />
          <v-subheader v-t="'occupantData'" />
          <v-btn-toggle v-model="addRoomData.occupied" mandatory color="primary" class="mb-3">
            <v-btn v-t="'employee'" class="text--primary" left outlined />
            <v-btn v-t="'occupied'" class="text--primary" right outlined />
          </v-btn-toggle>
          <v-autocomplete
            v-if="!addRoomData.occupied"
            v-model="addRoomData.occupants"
            :items="employeesChoices"
            outlined
            chips
            clearable
            deletable-chips
            multiple
            hide-details="auto"
            :label="$t('employee')"
            :placeholder="$t('employee')"
            :rules="[validators.required]"
            class="mb-3"
          />
          <v-text-field
            v-else
            v-model="addRoomData.note"
            :label="$t('note')"
            outlined
            dense
            maxlength="50"
            hide-details="auto"
            class="mb-3"
          />
          <upgrade-alert-wrapper :form="false" class="mb-3">
            <template #blocked-features>
              <v-menu
                close-on-content-click
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
                :disabled="!$can(ACTIONS.READ, PRIVATE.SHINE_FEATURES)"
              >
                <template v-slot:activator="activator">
                  <v-text-field
                    v-model="addRoomData.dateStart"
                    :disabled="!$can(ACTIONS.READ, PRIVATE.SHINE_FEATURES)"
                    :label="$t('accommodationDateStart')"
                    readonly
                    outlined
                    dense
                    clearable
                    v-bind="activator.attrs"
                    hide-details="auto"
                    :rules="[validators.dateRangeValidator(addRoomData.dateStart, addRoomData.dateEnd)]"
                    class="mb-3"
                    v-on="activator.on"
                  />
                </template>
                <v-date-picker v-model="addRoomData.dateStart" :first-day-of-week="1" :locale="$i18n.locale" />
              </v-menu>
              <v-menu
                :disabled="!$can(ACTIONS.READ, PRIVATE.SHINE_FEATURES)"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="activator">
                  <v-text-field
                    v-model="addRoomData.dateEnd"
                    :disabled="!$can(ACTIONS.READ, PRIVATE.SHINE_FEATURES)"
                    :label="$t('accommodationDateEnd')"
                    readonly
                    outlined
                    dense
                    clearable
                    v-bind="activator.attrs"
                    hide-details="auto"
                    :rules="[validators.dateRangeValidator(addRoomData.dateStart, addRoomData.dateEnd)]"
                    class="mb-3"
                    v-on="activator.on"
                  />
                </template>
                <v-date-picker v-model="addRoomData.dateEnd" :first-day-of-week="1" :locale="$i18n.locale" />
              </v-menu>
            </template>
          </upgrade-alert-wrapper>
        </v-card-text>
        <v-card-actions>
          <v-row class="overwritten-drawer-actions">
            <v-col cols="12" md="6">
              <v-btn color="primary" class="me-3" type="submit" block :loading="addLoading">
                {{ $t('add') }}
              </v-btn>
            </v-col>
            <v-col cols="12" md="6">
              <v-btn v-t="'discard'" color="secondary" outlined type="reset" block @click="discardAdd" />
            </v-col>
          </v-row>
        </v-card-actions>
      </v-form>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { ref, onMounted } from '@vue/composition-api'
import { useState, useActions } from 'vuex-composition-helpers'
import { mdiPlus, mdiClose, mdiChevronRight } from '@mdi/js'
import { required, minPositiveValue, dateRangeValidator } from '@core/utils/validation'
import { formatDate } from '@core/utils/filter'
import { useRouter } from '@core/utils'
import { ACTIONS, PRIVATE } from '@/plugins/acl/const'
import houseUtils from '@/utils/houseUtils'
import UpgradeAlertWrapper from '@/components/UpgradeAlertWrapper.vue'

export default {
  components: { UpgradeAlertWrapper },
  setup(_, { emit }) {
    const { getAllEmployeesChoices, createRoom } = useActions('staff', ['getAllEmployeesChoices', 'createRoom'])
    const { houseData } = useState('staff', ['houseData'])

    const roomsLoading = ref(false)
    const employeesChoices = ref([])

    const { router } = useRouter()

    const { formatOccupants } = houseUtils()

    onMounted(async () => {
      employeesChoices.value = await getAllEmployeesChoices()
    })

    const goToEmployeeDetailsPage = (employee) => {
      if (!employee || !employee.id) return
      const route = router.resolve({ name: 'apps-employee-view', params: { id: employee.id } })
      window.open(route.href, '_blank')
    }

    const addLoading = ref(false)
    const isAddRoomDrawerVisible = ref(false)
    const addRoomForm = ref(null)
    const addRoomData = ref({
      name: '',
      demand: 0,
      occupants: [],
      dateStart: null,
      dateEnd: null,
      occupied: 0,
      note: '',
    })

    const resetAddRoomData = () => {
      addRoomForm.value.reset()
      addRoomData.value = {
        name: '',
        demand: 0,
        occupants: [],
        dateStart: null,
        dateEnd: null,
        occupied: 0,
        note: '',
      }
    }

    const confirmAdd = async () => {
      const isFormValid = addRoomForm.value.validate()
      if (!isFormValid) return

      addLoading.value = true

      const success = await createRoom({
        name: addRoomData.value.name,
        demand: addRoomData.value.demand,
        house: houseData.value.id,
        occupants: formatOccupants(addRoomData.value),
      })
      if (success) {
        emit('refetch-data')
        isAddRoomDrawerVisible.value = false
        resetAddRoomData()
      }
      addLoading.value = false
    }

    const discardAdd = () => {
      isAddRoomDrawerVisible.value = false
      resetAddRoomData()
    }

    return {
      confirmAdd,
      discardAdd,
      goToEmployeeDetailsPage,

      houseData,
      roomsLoading,
      addLoading,
      isAddRoomDrawerVisible,
      addRoomForm,
      addRoomData,
      employeesChoices,

      validators: {
        required,
        minPositiveValue,
        dateRangeValidator,
      },

      formatDate,

      icons: {
        mdiChevronRight,
        mdiPlus,
        mdiClose,
      },
      ACTIONS,
      PRIVATE,
    }
  },
}
</script>
